import { DatagridConfigurable, List, TextField, useGetIdentity } from 'react-admin'
import { PostPagination } from '../../common/Pagination'

const SettingSmic = ({ value }) => {
  const { identity } = useGetIdentity()
  
  return (
    <List
      disableSyncWithLocation
      resource="saved_filters"
      filter={{ profile_id: identity?.id }}
      pagination={<PostPagination />}
      perPage={50}
      exporter={false}
      empty={false}
    >
      <DatagridConfigurable>
        <TextField source="name" label="Intitulé du pré-filtrage" />
      </DatagridConfigurable>
    </List>
  )
}

export default SettingSmic