import { supabaseClient } from '../supabase'
import baseDataProvider from '../supabase/baseDataProvider'

const profilesDataProvider = {
  ...baseDataProvider,
  getList: async (resource, params) => {
    if (params.filter.multi_search) {
      const localUserGrade = localStorage.getItem('localUserGrade') ?? 0

      let query = supabaseClient
        .from('profiles_view')
        .select('*, role:roles(*)')
        .order('created_at', { ascending: false })
        .or(`first_name.ilike.%${params.filter.multi_search}%,last_name.ilike.%${params.filter.multi_search}%,email.ilike.%${params.filter.multi_search}%,phone.ilike.%${params.filter.multi_search}%`)

      if (localUserGrade && Number(localUserGrade) < 100) {
        // query.url.href += `&and=(role_id.neq.597c594e-c095-4671-a2fa-bce91f2bf920)` // no vision on admins
        query = query.neq('role_id', '597c594e-c095-4671-a2fa-bce91f2bf920')
      }

      const { data, error } = await query

      if (error) {
        console.error('Error fetching multisearch profiles:', error.message)
      }

      return { data: data ?? [], total: data?.length ?? 0 }
    }

    resource = 'profiles_view'

    // Transforming filter object into filters array with operators
    const filter = params.filter
    const sorting = params.sort
    const operators = {
      '@gte': '>=',
      '@lte': '<=',
      '@neq': '!=',
      '@ilike': 'ilike',
      '@cs': 'cs',
      '@jsonb': 'jsonb',
    }

    // Transform filters object into filters array with operators
    const filters = Object.keys(filter).map(key => {
      const matchedOperator = Object.keys(operators).find(op => key.endsWith(op));
      const operator = matchedOperator ? operators[matchedOperator] : '=';

      // Remove the operator part from the key
      const field = matchedOperator ? key.slice(0, -matchedOperator.length) : key;

      return { field, operator, value: filter[key] };
    })

    // Transforming query params and applying filters in Supabase query
    let query = supabaseClient
      .from(resource)
      .select('*', { count: 'exact' })

    let customFilters = false
    filters.forEach(filterObj => {
      if ( !Array.isArray(filterObj.value) ) {
        if (filterObj.operator === '=') {
          query = query.eq(filterObj.field, filterObj.value)
        } else if (filterObj.operator === '>=') {
          query = query.gte(filterObj.field, filterObj.value)
        } else if (filterObj.operator === '<=') {
          query = query.lte(filterObj.field, filterObj.value)
        } else if (filterObj.operator === '!=') {
          query = query.neq(filterObj.field, filterObj.value)
        } else if (filterObj.operator === 'ilike') {
          query = query.ilike(filterObj.field, `%${filterObj.value}%`)
        } else if (filterObj.operator === 'cs') {
          query = query.cs(filterObj.field, JSON.parse(filterObj.value))
        } else if (filterObj.operator === 'jsonb') {
          query.url.href = `${query.url.href}&or=(and(info_paie.cs.[${filterObj.value}]))`
        }
      } else {
        customFilters = true

        // Handle array values
        if (filterObj.operator === '!=') {
          filterObj.value.map(val => {
            query = query.neq(filterObj.field, val)
          })
        } else if (filterObj.operator === '=') {
          filterObj.value.map(val => {
            query = query.eq(filterObj.field, val)
          })
        }
      }
    })

    if (!customFilters) {
      // Cleaning spaces in search params
      const nbrOfFilters = Object.keys(params?.filter); // because params.filter has current_year_id by default

      if ( params.filter && nbrOfFilters?.length > 0 ) {
        let filters = params.filter

        function replaceSpacesWithUnderscores(obj) {
          if ( typeof obj !== 'object' ) return obj

          const result = {}

          for (const key in obj) {
            if ( typeof obj[key] === 'string') {
              const modifiedValue = obj[key].replace(/\s/g, '_')
              result[key] = modifiedValue
            } else {
              result[key] = obj[key]
            }
          }

          return result
        }

        const newFilters = replaceSpacesWithUnderscores(filters)

        params.filter = newFilters
      }
      
      return baseDataProvider.getList(resource, params)
    }

    const { data, error, count } = await query
      .order(sorting?.field || 'created_at', { ascending: sorting?.order === 'ASC' })
      .range(
        (params.pagination.page - 1) * params.pagination.perPage,
        params.pagination.page * params.pagination.perPage - 1
      )

    if (error) {
      console.error('Error fetching profiles:', error.message)
      return { data: [], total: 0 }
    }

    return {
      data: data ?? [],
      total: count ?? 0,
    }
  },
}

export const dataProvider = profilesDataProvider
