import { supabaseAuthProvider } from 'ra-supabase'
import { supabaseClient } from './'
import { getPermissionsFromRoles } from '@react-admin/ra-rbac'

import permissions from '../permissions'

const baseAuthProvider = supabaseAuthProvider(supabaseClient, {
  getIdentity: async profile => {
    const { data, error } = await supabaseClient
      .from('profiles')
      .select(
        `
          id,
          first_name,
          last_name,
          email,
          phone,
          role: role_id (id, name, reference, grade),
          centers: profile_centers ( centers (id, name) ),
          tours
        `
      )
      .match({ user_auth_id: profile.id })
      .single()

    if (!data || error) {
      throw new Error()
    }

    const profilePermissions = getPermissionsFromRoles({
      roleDefinitions: permissions,
      userRoles: [data.role.grade],
    })

    const currentPermissions = JSON.parse(localStorage.getItem('permissions'))
    if (JSON.stringify(currentPermissions) !== JSON.stringify(profilePermissions)) {
      localStorage.setItem('permissions', JSON.stringify(profilePermissions))
    }

    return {
      id: data.id,
      first_name: data.first_name,
      last_name: data.last_name,
      fullName: `${data.first_name} ${data.last_name}`,
      email: data.email,
      phone: data.phone,
      role: data.role,
      center: data.centers[0].centers,
      tours: data.tours,
    }
  }
})

const authProvider = baseAuthProvider

export default authProvider
