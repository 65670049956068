import * as React from 'react'
import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Toolbar,
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

export const TransparentDialog = ({ open, handleClose, size, minHeight, children, filterTitle, displayDialogActions = true }) => (
  <Dialog
    open={open}
    fullWidth
    maxWidth={size || 'lg'}
    onClose={handleClose}
    SlotProps={{
      input: {
        paper: {
          borderRadius: '10px',
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
        },
      },
      backdrop: { style: { backgroundColor: 'rgba(255, 255, 255, 0.4)' } }
    }}
  >
    { displayDialogActions && (
      <DialogActions style={{ dislay: 'flex', justifyContent: 'space-between' }}>
        <span style={{ padding: '5px', fontWeight: 'bold', fontSize: '1.2rem', color: '#444' }}>{filterTitle}</span>

        <Button onClick={handleClose} color="primary">
          <HighlightOffIcon style={{ color: '#999', fontSize: '1.2rem' }}/>
        </Button>
      </DialogActions>
    ) }

    <DialogContent style={{ minHeight: minHeight ?? '200px', overflow: 'auto', padding: '20px' }}>
      { children }
    </DialogContent>
  </Dialog>
)

export const CommonDialog = ({ open, handleClose, title, size, minHeight, children, displayDialogActions = true, titleBgColor = '#c5dedd', titleColor = '#434343', widthInPx = false, icon = null }) => (
  <Dialog
    open={open}
    fullWidth
    maxWidth={size ?? 'lg'}
    onClose={handleClose}
    sx={ widthInPx ? { width: widthInPx, margin: '0 auto' } : {} }
  >
    <DialogTitle sx={{ m: 0, p: 0 }} id="form-dialog-title">
      <Toolbar sx={{ color: titleColor, backgroundColor: titleBgColor, boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}>
        {icon}
        <span style={{ marginLeft: '10px' }}>{title}</span>
        
        <IconButton
          aria-label="close"
          color="inherit"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ mt: .5, pb: .5, pl: .4, pr: .4 }} />
        </IconButton>
      </Toolbar>
    </DialogTitle>

    <DialogContent sx={{ minHeight: minHeight ?? '200px', overflow: 'auto', padding: '20px', mt: 2 }}>
      {children}
    </DialogContent>
    
    {displayDialogActions && (
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Fermer
        </Button>
      </DialogActions>
    )}
  </Dialog>
)

const CustomDialog = ({ open, handleClose, title, size, style, children }) => (
  <Dialog
    open={open}
    onClose={handleClose}
    fullWidth
    maxWidth={size ?? 'md'}
    sx={{ style }}
  >
    <DialogTitle sx={{ m: 0, p: 2 }}>
      <Toolbar>
        {title}
        <IconButton
          aria-label="close"
          color="inherit"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </DialogTitle>

    <DialogContent>
      {children}
    </DialogContent>
  </Dialog>
)

export default CustomDialog
