import { useState, useEffect, useMemo } from 'react'
import {
  ArrayInput,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  useGetIdentity,
  useRecordContext,
  AutocompleteInput,
  DatagridConfigurable,
  IconButtonWithTooltip,
} from 'react-admin'
import { FormControl, Select as MUIselect, InputLabel, MenuItem, Box, Typography, Stack } from '@mui/material'

import { getRole } from '../../common/roles'
import { supabaseClient } from '../../supabase'
import DashboardToolbar from './DashboardToolbar'
import { TextFieldCopy } from '../../Design/CustomTextField'
import { FormCard, PaieCardWithIcon } from '../../Design/Cards'

import RefreshIcon from '@mui/icons-material/Refresh'
import LinearProgress from '@mui/material/LinearProgress'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import MoneyOffCsredIcon from '@mui/icons-material/MoneyOffCsred'

const EmptyPaie = () => (
  <Stack direction="column" spacing={4} alignItems="center" mt={6}>
    <MoneyOffCsredIcon color="ternary" sx={{ fontSize: '40pt' }} />

    <Typography variant="h6">
      Aucune paie n'est à vérifer pour ce mois.
    </Typography>
  </Stack>
)

const ExpandPubeur = ({ selectedMonth }) => {
  const record = useRecordContext()
  const [paiePubeurs, setPaiePubeurs] = useState(null)
  const [totalPubeur, setTotalPubeur] = useState(0)

  const fetchPaiePubeurs = async () => {
    if (!record || !selectedMonth) return
  
    try {
      const [monthName, year] = selectedMonth.toLowerCase().split(' ')
      const month = monthNamesFR[monthName]
      
      // For a given month, period is from 16th of PREVIOUS month to 15th of CURRENT month
      const startOfMonth = new Date(Number(year), month - 1, 16)  // 16th of previous month
      const endOfMonth = new Date(Number(year), month, 15) // 15th of current month
  
      const { data, error } = await supabaseClient
        .from('paie_pubeur')
        .select('*')
        .eq('profile_id', record.id)
        .gte('created_at', startOfMonth.toISOString())
        .lt('created_at', new Date(endOfMonth.getTime() + 86400000).toISOString())
  
      if (error) {
        console.error('Error fetching data:', error)
        return
      }
  
      setPaiePubeurs(data)
      const total = data.reduce((acc, pubeur) => acc + (pubeur.amount * pubeur.quantity), 0).toFixed(2)
      setTotalPubeur(total)
    } catch (err) {
      console.error('Error in fetchPaiePubeurs:', err)
    }
  }

  // Fetch paie_pubeurs for the current pubeur when selected month or record changes
  useEffect(() => {
    fetchPaiePubeurs()
  }, [record, selectedMonth])

  const handleSubmit = async (values) => {
    const { paie_pubeurs } = values

    const newRows = paie_pubeurs.filter(p => !p.id) // Rows without 'id' are new
    const updatedRows = paie_pubeurs.filter(p => p.id) // Rows with 'id' are updates

    const newValues = newRows.map(pubeur => ({
      ...pubeur,
      profile_id: record.id,
    }))

    const updateValues = updatedRows.map(pubeur => ({
      ...pubeur,
      profile_id: record.id,
    }))

    const deletedValues = paiePubeurs.filter(pubeur => !paie_pubeurs.find(p => p.id === pubeur.id))

    try {
      if (!record) return

      // Insert new rows
      if (newValues.length > 0) {
        const { error } = await supabaseClient
          .from('paie_pubeur')
          .insert(newValues)

        if (error) {
          console.error('Error inserting new rows:', error)
        }
      }

      // Update existing rows
      if (updateValues.length > 0) {
        const { error } = await supabaseClient
          .from('paie_pubeur')
          .upsert(updateValues, { onConflict: ['id'] })

        if (error) {
          console.error('Error updating rows:', error)
        }
      }

      // Delete rows
      if (deletedValues.length > 0) {
        const { error } = await supabaseClient
          .from('paie_pubeur')
          .delete()
          .in('id', deletedValues.map(p => p.id))

        if (error) {
          console.error('Error deleting rows:', error)
        }
      }

      // Refetch the data to update the UI
      fetchPaiePubeurs()
    } catch (err) {
      console.error('Error during submission:', err)
    }
  }

  return paiePubeurs ? (
    <FormCard>
      <SimpleForm onSubmit={handleSubmit} record={{ paie_pubeurs: paiePubeurs }}>
        <ArrayInput source="paie_pubeurs" label={false}>
          <SimpleFormIterator inline>
            <ReferenceInput source="mission_id" reference="paie_missions">
              <AutocompleteInput optionText="name" label="Mission" sx={{ width: '49%' }} />
            </ReferenceInput>
            <ReferenceInput source="unit_id" reference="paie_units">
              <AutocompleteInput optionText="name" label="Unité de mesure" sx={{ width: '20%' }} />
            </ReferenceInput>
            <NumberInput source="amount" label="Montant" sx={{ width: '13%' }} />
            <NumberInput source="quantity" label="Quantité" sx={{ width: '12%' }} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>

      <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'right' }} gutterBottom>
        {`TOTAL BRUT : ${totalPubeur} €`}
        <IconButtonWithTooltip
          label="Rafraîchir"
          onClick={fetchPaiePubeurs}
        >
          <RefreshIcon />
        </IconButtonWithTooltip>
      </Typography>
    </FormCard>
  ) : <LinearProgress />
}

const monthNamesFR = {
  'janvier': 0,
  'février': 1,
  'mars': 2,
  'avril': 3,
  'mai': 4,
  'juin': 5,
  'juillet': 6,
  'août': 7,
  'septembre': 8,
  'octobre': 9,
  'novembre': 10,
  'décembre': 11
}

const getPreviousMonths = (count) => {
  const months = []
  const currentDate = new Date()

  for (let i = 0; i < count; i++) {
    const monthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1)
    months.push(monthDate.toLocaleString('fr', { month: 'long', year: 'numeric' }))
  }

  return months
}

const DashboardRespoPaies = () => {
  const { identity } = useGetIdentity()
  const isAdmin = identity?.role?.grade === getRole('admin')
  
  // French to English month name conversion
  const frToEnMonth = (frMonth) => {
    const [month, year] = frMonth.split(' ')
    
    const monthMap = {
      'janvier': 'January',
      'février': 'February',
      'mars': 'March',
      'avril': 'April',
      'mai': 'May',
      'juin': 'June',
      'juillet': 'July',
      'août': 'August',
      'septembre': 'September',
      'octobre': 'October',
      'novembre': 'November',
      'décembre': 'December'
    }

    return `${monthMap[month.toLowerCase()]} ${year}`
  }

  // Set the default selected month to the current month (in French)
  let currentMonth = new Date().toLocaleString('fr', { month: 'long', year: 'numeric' })
  if (new Date().getDate() > 14) {
    const nextMonth = new Date()
    nextMonth.setMonth(nextMonth.getMonth() + 1)
    currentMonth = nextMonth.toLocaleString('fr', { month: 'long', year: 'numeric' })
  }

  const [month, setMonth] = useState(currentMonth)
  
  const handleChangeMonth = (event) => {
    setMonth(event.target.value)
  }

  // Check if the selected month is the current month
  const isCurrentMonth = month === currentMonth

  const listFilters = useMemo(() => {
    const monthToDisplay = frToEnMonth(month)

    const baseFilters = {
      enabled: true,
      'role_id@neq': ['597c594e-c095-4671-a2fa-bce91f2bf920', 'e7d5673e-9f94-4229-a1b4-bb1f4dcd71de'],
      'info_paie@jsonb': `{"${monthToDisplay}":"true"}`
    }

    if (!isAdmin) {
      return {
        ...baseFilters,
        center_id: identity?.center?.id,
      }
    }

    return baseFilters
  }, [month, isAdmin, identity?.center?.id])

  return (
    <Box sx={{ p: 0 }}>
      <PaieCardWithIcon
        icon={AccountBalanceIcon}
        title={month.charAt(0).toUpperCase() + month.slice(1)}
        subtitle={isCurrentMonth
          ? "Vous pouvez éditer les paies jusqu'au 14 du mois suivant à 23h59."
          : "Archive pour consultation uniquement"}
        colorTitle="#fbc410"
      >
        <div style={{ width: '30%' }}>
          <FormControl variant="outlined" sx={{ m: .5, minWidth: '100%' }}>
            <InputLabel id="monthLabel">Consulter</InputLabel>
            <MUIselect
              labelId="monthLabel"
              id="select-monthLabel"
              value={month}
              variant="outlined"
              onChange={handleChangeMonth}
              label="Consulter"
            >
              {getPreviousMonths(12).map((monthValue, index) => (
                <MenuItem key={index} value={monthValue}>{monthValue}</MenuItem>
              ))}
            </MUIselect>
          </FormControl>
        </div>
      </PaieCardWithIcon>
      <List
        resource="profiles"
        basePath="/profiles"
        perPage={25}
        filter={listFilters}
        exporter={false}
        actions={false}
        sx={{ mt: 2 }}
        empty={<EmptyPaie />}
      >
        <DashboardToolbar isAdmin={isAdmin} />
        <DatagridConfigurable
          bulkActionButtons={false}
          rowClick="expand"
          expand={<ExpandPubeur selectedMonth={month} />}
          expandSingle
        >
          <TextField source="first_name" label="Prénom" />
          <TextField source="last_name" label="Nom" />
          <TextFieldCopy source="id" label="ID" truncated />
          <ReferenceField source="role_id" reference="roles" label="Role" link={false}>
            <TextField source="name" />
          </ReferenceField>
        </DatagridConfigurable>
      </List>
    </Box>
  )
}

export default DashboardRespoPaies
