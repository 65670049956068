import { useGetIdentity } from 'react-admin'
import { getRole } from '../common/roles'

import DashboardPubeur from './pubeur'
import DashboardRespo from './respo'
import DashboardHippocast from './hippocast'

export default () => {
  const { identity } = useGetIdentity()
  const isHippocast = identity?.role?.grade === getRole('hippocast')
  const isResponsable = identity?.role?.grade >= getRole('responsable')

  return isResponsable
    ? <DashboardRespo />
    : isHippocast
      ? <DashboardHippocast />
      : <DashboardPubeur />
}