import { getPermissionsFromRoles, canAccessWithPermissions } from '@react-admin/ra-rbac'
import { getItemFromLocalStorage, setItemToLocalStorage } from '../common/LocalStorage'
import authProvider from './authProvider'

import { clear } from 'idb-keyval'
import permissions from '../permissions'

const customAuthProvider = (roleGrade) => {
  const getIdentity = async (profile) => {
    const identity = await authProvider.getIdentity(profile)

    const storedRoleGrade = getItemFromLocalStorage('localUserGrade') ?? identity?.role?.grade
    const setRoleGrade = roleGrade || storedRoleGrade || identity?.role?.grade

    const profilePermissions = getPermissionsFromRoles({
      roleDefinitions: permissions,
      userRoles: [setRoleGrade],
    })

    const currentPermissions = JSON.parse(localStorage.getItem('permissions'))
    if (JSON.stringify(currentPermissions) !== JSON.stringify(profilePermissions)) {
      setItemToLocalStorage('permissions', JSON.stringify(profilePermissions))
    }

    return {
      ...identity,
      role: {
        ...identity.role,
        grade: setRoleGrade ?? identity.role.grade,
      },
    }
  }

  return {
    ...authProvider,
    getIdentity,
    canAccess: async ({ resource, action, record }) => {
      const permissions = JSON.parse(localStorage.getItem('permissions'))
  
      // check if the user can access the resource and action (RBAC components)
      return canAccessWithPermissions({ permissions, resource, action, record })
    },
    logout: async () => {
      await clear() // Clear IndexedDB first
      localStorage.clear() // Clear local storage completely
      return authProvider.logout()
    }
  }
}

export default customAuthProvider
