import { useEffect, useState, useMemo, useCallback } from 'react'
import { useGetIdentity, useNotify, useRedirect } from 'react-admin'
import { ContainerLayout, Header } from '@react-admin/ra-navigation'
import { TourProvider } from '@react-admin/ra-tour'
import { Stack, useMediaQuery } from '@mui/material'
import { isEqual } from 'lodash'

import Tours from './Tours'
import { getRole } from './common/roles'
import { supabaseClient } from './supabase'
import LoadingPage from './Design/LoadingPage'
import CustomBreadCrumb from './CustomBreadCrumb'
import dataProvider from './supabase/dataProvider'
import CustomUserMenu, { MyMenu } from './UserMenu'
import FrontUpdateAvailable from './Design/FrontUpdateAvailable'
import { useServiceWorker } from './contexts/ServiceWorkerContext'
import { getItemFromLocalStorage, setItemToLocalStorage } from './common/LocalStorage'
import fetchEvents from './common/fetchEvents'

const CustomAppBar = ({ isMobile, isPubeur }) =>
  <Header title={(isMobile || ! isPubeur) ? '' : 'Forma Contacts'} />

const getLeadYears = async () => {
  const { data, error } = await supabaseClient
    .from('lead_years')
    .select('id, name')
    .order('created_at', { ascending: false })

  if ( error ) {
    console.error('Error getting lead years: ', error)
    return []
  }

  return data
}

export const MyLayout = ({ children, ...rest }) => {
  const { isUpdateAvailable } = useServiceWorker()
  
  const { identity } = useGetIdentity()
  const notify = useNotify()
  const redirect = useRedirect()
  const role = identity?.role?.grade ?? null
  const isPubeur = role < getRole('responsable')
  const isAdmin = role === getRole('admin')
  const isMobile = useMediaQuery(
    theme => theme.breakpoints.down('md'),
    { noSsr: true }
  )
  const [profileTours, setProfileTours] = useState(null)
  const [maintenanceStyle, setMaintenanceStyle] = useState({display: 'none'})

  // Memoized localStorage values
  const defaultCurrentYearLocal = useMemo(() => getItemFromLocalStorage('localCurrentYear'), [])
  const cachedLeadYears = useMemo(() => getItemFromLocalStorage('leadYears', null, Array.isArray), [])
  const localCenter = useMemo(() => getItemFromLocalStorage('localCenter'), [])
  const localUserGrade = useMemo(() => getItemFromLocalStorage('localUserGrade'), [])
  const localUserId = useMemo(() => getItemFromLocalStorage('localUserId'), [])
  const maintenanceStatusCheck = useMemo(() => getItemFromLocalStorage('maintenanceStatusCheck'), [])
  const centerTags = useMemo(() => getItemFromLocalStorage('centerTags'), [])
  const eventsInfo = useMemo(() => getItemFromLocalStorage('eventsInfo'), [])
  const profileToursStatus = useMemo(() => getItemFromLocalStorage('profileToursStatus'), [])
  
  useEffect(() => {
    if ( ! identity ) return

    const fetchData = async () => {
      if (!identity) return
    
      try {
        const promisesMap = {}

        if (!defaultCurrentYearLocal) {
          promisesMap.localCurrentYear = dataProvider.getOne('lead_years', { id: process.env.REACT_APP_CURRENT_YEAR_ID })
        }

        if (!cachedLeadYears) {
          promisesMap.leadYears = getLeadYears()
        }

        if (!localCenter && identity?.center) {
          promisesMap.localCenter = Promise.resolve(identity?.center)
        }

        if (!localUserGrade && role) {
          promisesMap.localUserGrade = Promise.resolve(role)
        }

        if (!localUserId && identity?.id) {
          promisesMap.localUserId = Promise.resolve(identity?.id)
        }

        const resultsArray = await Promise.all(Object.values(promisesMap))

        // Map resolved results back to keys
        const results = Object.keys(promisesMap).reduce((acc, key, index) => {
          acc[key] = resultsArray[index]
          return acc
        }, {})

        // Store values in localStorage
        if (results.localCurrentYear?.data) {
          setItemToLocalStorage('localCurrentYear', results.localCurrentYear.data)
        }

        if (results.leadYears) {
          setItemToLocalStorage('leadYears', results.leadYears)
        }

        if (results.localCenter) {
          setItemToLocalStorage('localCenter', results.localCenter)
        }

        if (results.localUserGrade) {
          setItemToLocalStorage('localUserGrade', results.localUserGrade)
        }

        if (results.localUserId) {
          setItemToLocalStorage('localUserId', results.localUserId)
        }
      } catch (error) {
        console.error('Error setting up local data for the current user', error)
      }
    }

    fetchData()
  }, [identity])
  
  useEffect(() => {
    const getData = async () => {
      const promises = []
  
      promises.push(
        supabaseClient
          .from('isUnderMaintenance')
          .select('status')
          .single()
      )
  
      if (!centerTags || Date.now() - centerTags.updatedAt > 600000) {
        promises.push(
          supabaseClient
            .from('tags')
            .select('*')
            .eq('center_id', identity?.center?.id)
        )
      } else {
        promises.push(Promise.resolve(null))
      }
  
      if (!eventsInfo || Date.now() - eventsInfo.updatedAt > 120000) {
        const currentTimestamp = isPubeur ? new Date().toISOString() : null
        promises.push(fetchEvents(identity?.center?.id, identity, currentTimestamp))
      } else {
        promises.push(Promise.resolve(null))
      }
  
      const [maintenanceResult, tagsResult, eventsResult] = await Promise.all(promises)
  
      if (maintenanceResult?.data) {
        setMaintenanceStyle({
          display: maintenanceResult.data.status ? 'flex' : 'none',
        })
        setItemToLocalStorage('maintenanceStatusCheck', Date.now())
      }
  
      if (tagsResult?.data) {
        setItemToLocalStorage('centerTags', { tags: tagsResult.data, updatedAt: Date.now() })
      }
  
      if (eventsResult) {
        const { data: qualifications } = await supabaseClient
          .from('types_qualifications')
          .select('id,name')
  
        for (const event of eventsResult) {
          if (event.date) {
            const [datePart] = event.date.split('T')
            event.startDate = datePart || null
          }
  
          if (event.startDate) {
            const [year, month, day] = event.startDate.split('-')
            event.startDate = `${day}/${month}/${year}`
          }
  
          if (event.qualifications_ids) {
            event.qualificationInfo = event.qualifications_ids.map(id =>
              qualifications.find(q => q.id === id)
            )
          }
        }
  
        eventsResult.sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
        setItemToLocalStorage('eventsInfo', { events: eventsResult, updatedAt: Date.now() })
      }
    }
  
    if (
      identity &&
      (!maintenanceStatusCheck || maintenanceStatusCheck < Date.now() - 3600000)
    ) {
      getData()
    }
  }, [identity, maintenanceStatusCheck])
  
  useEffect(() => {
    if (!identity || !profileTours) return
  
    const updateProfile = async () => {
      console.log('Init. Updating profile tours...')
  
      // Update localStorage for tracking
      setItemToLocalStorage('profileToursStatus', profileTours)
  
      // Update tours status in the database
      const { error } = await supabaseClient
        .from('profiles')
        .update({ tours: profileTours })
        .eq('id', identity.id)
        .single()
  
      if (error) {
        console.error('Error updating profile: ', error)
      }
    }
  
    // Check if the tours have changed (e.g., new tours added)
    if (!profileToursStatus || !isEqual(profileToursStatus, profileTours)) {
      updateProfile()
    }
  }, [identity?.id, profileTours, profileToursStatus])

   // Styles
   const memoizedStyle = useMemo(() => ({
    ...maintenanceStyle,
    backgroundColor: '#f79525',
    padding: '10px',
    fontSize: '.9rem',
    fontWeight: 600,
  }), [maintenanceStyle])

  const toolbarStyles = useMemo(() => ({
    '& .RaHeader-toolbar .MuiTab-root': {
      minWidth: isAdmin ? '50px' : role >= getRole('responsable') ? '55px' : '70px',
      typography: {
        fontSize: isAdmin || role >= getRole('responsable') ? '.6rem' : '.8rem',
        fontWeight: isAdmin || role >= getRole('responsable') ? 300 : 500,
        padding: isAdmin || role >= getRole('responsable') ? '0px 5px' : '0px 15px'
      }
    }
  }), [isAdmin, role])

  return identity ? (
    <>
      {isUpdateAvailable && <FrontUpdateAvailable />}

      <TourProvider tours={Tours} tools={{ notify, redirect, profileTours, setProfileTours }}>
        <Stack 
          direction="row" 
          spacing={2} 
          alignItems="center" 
          justifyContent="center" 
          sx={memoizedStyle}
        >
          Une opération de maintenance est en cours. Merci de votre compréhension.
        </Stack>

        <ContainerLayout
          {...rest}
          appBar={<CustomAppBar isMobile={isMobile} isPubeur={isPubeur} />}
          maxWidth="xl"
          menu={<MyMenu role={role} {...rest} />}
          userMenu={<CustomUserMenu />}
          sx={toolbarStyles}
        >
          <CustomBreadCrumb />
          {children}
        </ContainerLayout>
      </TourProvider>
    </>
  ) : <LoadingPage />
}