export const setItemToLocalStorage = (key, value) => {
  try {
    if (!key || typeof key !== 'string') {
      throw new Error('Invalid key: Key must be a non-empty string.')
    }

    if (!value) {
      throw new Error('Invalid value: null or undefined.')
    }

    const serializedValue = JSON.stringify(value)
    localStorage.setItem(key, serializedValue)
  } catch (error) {
    console.error(`Error saving to localStorage key "${key}":`, error)
  }
}

export const getItemFromLocalStorage = (key, fallback = null, validator = null) => {
  try {
    const item = localStorage.getItem(key)
    const parsed = item ? JSON.parse(item) : fallback

    // If a validator is provided, validate the parsed value
    if (validator && item && !validator(parsed)) {
      console.warn(`Validation failed for localStorage key "${key}". Returning fallback.`)
      return fallback
    }
    
    return parsed
  } catch (error) {
    // Log the error, remove the corrupted item, and return the fallback value
    console.error(`Error parsing localStorage item "${key}":`, error)
    localStorage.removeItem(key)
    return fallback
  }
}
