import { useState, useEffect } from 'react'
import { useGetIdentity } from 'react-admin'
import { List, ListItem, ListItemText, Avatar, ListItemAvatar } from '@mui/material'

import { supabaseClient } from '../../supabase'
import { CardWithIcon } from '../../Design/Cards'

import TaskIcon from '@mui/icons-material/Task'

const DashboardPubeurTasks = ({ isMobile }) => {
  const { identity } = useGetIdentity()
  const [pubeurTasks, setPubeurTasks] = useState([])

  useEffect(() => {
    if (!identity?.id) return

    const fetchPubeurTasks = async () => {
      try {
        const { data, error } = await supabaseClient
          .from('lead_tasks')
          .select('progress, leads (assigned_pubeur, current_year_id), tasks (name)')
          .eq('leads.assigned_pubeur', identity.id)
          .eq('leads.current_year_id', process.env.REACT_APP_CURRENT_YEAR_ID)

        if (error) {
          console.error('Error fetching tasks:', error)
          return
        }

        const taskSummary = data.reduce((acc, item) => {
          if (!item?.tasks || !item?.leads) return acc

          const { name } = item.tasks
          const progress = item.progress

          if (!acc[name]) {
            acc[name] = { name, completed: 0, waiting: 0, not_started: 0 }
          }

          if (progress === '100') acc[name].completed++
          else if (progress > 0 && progress < 100) acc[name].waiting++
          else acc[name].not_started++

          return acc
        }, {})

        setPubeurTasks(Object.values(taskSummary))
      } catch (error) {
        console.error('Unexpected error:', error)
      }
    }

    fetchPubeurTasks()
  }, [identity?.id])

  return (
    <div style={{ width: isMobile ? '100%' : '33.33333%' }}>
      <CardWithIcon
        icon={TaskIcon}
        title="Tâches attribuées"
        subtitle={pubeurTasks.length || undefined}
        to="/leads"
        color="#ccc"
      >
        <List sx={{ minHeight: '40vh' }} dense>
          {pubeurTasks.length > 0 ? (
            pubeurTasks.map((task, index) => (
              <ListItem key={index}>
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: '#91bcba' }}>
                    <TaskIcon />
                  </Avatar>
                </ListItemAvatar>

                <ListItemText
                  primary={task.name}
                  secondary={[
                    task.completed > 0 && `Complétées : ${task.completed}`,
                    task.waiting > 0 && `En cours : ${task.waiting}`,
                    task.not_started > 0 && `En attente : ${task.not_started}`
                  ].filter(Boolean).join(', ')}
                />
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText primary="Aucune tâche attribuée" />
            </ListItem>
          )}
        </List>
      </CardWithIcon>
    </div>
  )
}

export default DashboardPubeurTasks
