import { useEffect, useState } from 'react'
import { useGetIdentity } from 'react-admin'
import { Typography, Box, List, ListItem, ListItemText, Avatar, ListItemAvatar } from '@mui/material'

import { supabaseClient } from '../../supabase'
import { CardWithIcon } from '../../Design/Cards'

import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol'

const DashboardPubeurFrais = ({ isMobile }) => {
  const { identity } = useGetIdentity()
  const [paidData, setPaidData] = useState({
    totalAmountSMS: 0,
    totalMessages: 0,
    totalAmountEvents: 0,
    totalHours: 0
  })

  const today = new Date()
  const currentMonthDate = today.getDate() >= 16
    ? new Date(today.getFullYear(), today.getMonth() + 1, 1) // Next month
    : new Date(today.getFullYear(), today.getMonth(), 1) // Current month

  const currentMonth = new Intl.DateTimeFormat('fr', { month: 'long', year: 'numeric' }).format(currentMonthDate)

  useEffect(() => {
    if (!identity?.id) return

    const startOfPeriod = today.getDate() >= 16
      ? new Date(today.getFullYear(), today.getMonth(), 16)
      : new Date(today.getFullYear(), today.getMonth() - 1, 16)

    const endOfPeriod = today.getDate() >= 16
      ? new Date(today.getFullYear(), today.getMonth() + 1, 15)
      : new Date(today.getFullYear(), today.getMonth(), 15)

    const fetchPaidSMS = async () => {
      try {
        const { data, error } = await supabaseClient
          .from('conversation_messages')
          .select('paid')
          .eq('sender_type', 'pubeur')
          .eq('sender_id', identity.id)
          .gte('submited_at', startOfPeriod.toISOString())
          .lte('submited_at', endOfPeriod.toISOString())

        if (error) throw error

        if (!data?.length) return { totalAmountSMS: 0, totalMessages: 0 }

        const totalMessages = data.filter(sms => sms.paid).length
        return {
          totalAmountSMS: Math.round(totalMessages * 0.25 * 10) / 10, // 1 decimal
          totalMessages
        }
      } catch (error) {
        console.error('Error fetching paid SMS:', error)
        return { totalAmountSMS: 0, totalMessages: 0 }
      }
    }

    const fetchPaidEvents = async () => {
      try {
        const { data, error } = await supabaseClient
          .from('event_pubeurs')
          .select('is_driver, paid_hours, eventInfo: events(missionDuration, missionAmountHor, missionDurationAller)')
          .eq('pubeur_id', identity.id)

        if (error) throw error

        if (!data?.length) return { totalAmountEvents: 0, totalHours: 0 }

        let totalHours = 0
        const totalAmountEvents = data.reduce((acc, e) => {
          const { missionDuration, missionAmountHor, missionDurationAller } = e.eventInfo
          const effectiveHours = e.paid_hours || missionDuration || 0
          const isDriver = e.is_driver

          totalHours += effectiveHours
          acc += effectiveHours * missionAmountHor

          if (missionDurationAller) {
            totalHours += missionDurationAller
            acc += isDriver
              ? missionAmountHor * missionDurationAller * 2
              : missionAmountHor * missionDurationAller
          }

          return acc
        }, 0)

        return { totalAmountEvents, totalHours }
      } catch (error) {
        console.error('Error fetching paid events:', error)
        return { totalAmountEvents: 0, totalHours: 0 }
      }
    }

    const fetchAllData = async () => {
      const [smsData, eventsData] = await Promise.all([fetchPaidSMS(), fetchPaidEvents()])
      setPaidData({ ...smsData, ...eventsData })
    }

    fetchAllData()
  }, [identity?.id])

  return (
    <div style={{ width: isMobile ? '100%' : '33.33333%' }}>
      <CardWithIcon icon={EuroSymbolIcon} title="Déclarations de frais" to="/leads" color="#ccc">
        <Box sx={{ p: 2, minHeight: '40vh' }}>
          <List dense={false}>
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ backgroundColor: '#91bcba' }}>
                  <QuestionAnswerIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`SMS phoning : ${paidData.totalAmountSMS} €`}
                secondary={`${paidData.totalMessages} messages comptabilisés`}
              />
            </ListItem>
            
            {/* <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ backgroundColor: '#91bcba' }}>
                  <SchoolIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`Missions lycée : ${paidData.totalAmountEvents} €`}
                secondary={`${paidData.totalHours} heures comptabilisées`}
              />
            </ListItem> */}
          </List>

          <Typography variant="body2" mt={2} gutterBottom>
            <i>Montants actuels estimés pour le mois de {currentMonth}, sous réserve de validation par votre responsable.</i>
          </Typography>
        </Box>
      </CardWithIcon>
    </div>
  )
}

export default DashboardPubeurFrais
