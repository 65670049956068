import React, { useContext, useState } from 'react'
import { useLogout } from 'react-admin'
import { Button, Dialog, DialogContent, Typography, DialogTitle, Slide } from '@mui/material'

import UpdatingPage from './UpdatingPage'
import { useServiceWorker } from '../contexts/ServiceWorkerContext'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const FrontUpdateAvailable = () => {
  const { isUpdateAvailable, updateAssets } = useServiceWorker()
  const logout = useLogout()

  const handleReload = () => {
    if ( isUpdateAvailable ) {
      updateAssets()

      setTimeout(() => {
        logout()
      }, 5000)
    }
  }

  return (
    <>
      <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleReload}
      >
        <DialogTitle>✨ Nouvelle mise à jour</DialogTitle>

        <DialogContent>
          <Typography variant="body1" mt={2} gutterBottom>
            Cliquez sur continuer et patientez un instant.
          </Typography>
        </DialogContent>

        <Button color="primary" variant="contained" onClick={handleReload} sx={{ maxWidth: '200px', margin: '20px auto' }}>
          Continuer
        </Button>
      </Dialog>
    
      <UpdatingPage />
    </>
  )
}

export default FrontUpdateAvailable
