import { createElement } from 'react'
import { useTheme } from '@mui/material/styles'
import { Stack, Card, Box, Typography, Divider, CardContent, useMediaQuery } from '@mui/material'
import { Link } from 'react-router-dom'

export const FormCard = ({ colorTitle, title, cardWidth, marginTop = 2, children }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(
    theme => theme.breakpoints.down('sm'),
    { noSsr: true }
  )
  const trueCardWidth = isMobile ? '100%' : cardWidth ?? '100%'

  if ( ! colorTitle ) colorTitle = theme.palette.cards.title.main

  return (
    <Card variant="outlined" sx={{ mt: marginTop, borderRadius: 2, width: trueCardWidth, mb: 2, backgroundColor: theme.palette.cards.backgroundColor.main }}>
      <CardContent
        sx={{ '& .MuiInputBase-root': {
          backgroundColor: theme.palette.cards.contentBackgroundColor.main,
        } }}
      >
        {title && (
          <Typography variant="h6" color={colorTitle} mb={2} gutterBottom>
            {title}
          </Typography>
        )}
        {children}
      </CardContent>
    </Card>
  )
}

export const CardWithIcon = ({ icon, title, subtitle, to, children, color, }) => (
  <Card
    sx={{
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
      mb: 2,
      '& a': {
        textDecoration: 'none',
        color: 'inherit',
      }
    }}
  >
    <Link to={to}>
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          padding: '16px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          minHeight: '11vh',
          '& .icon': {
            color: color || 'complementary.main',
          },
          '&:before': {
            position: 'absolute',
            top: '50%',
            left: 0,
            display: 'block',
            content: `''`,
            height: '200%',
            aspectRatio: '1',
            transform: 'translate(-30%, -60%)',
            borderRadius: '50%',
            backgroundColor: 'complementary.main',
            opacity: 0.15,
          },
        }}
      >
        <Box width="3em" className="icon">
          { icon ? createElement(icon, { fontSize: 'large' }) : undefined }
        </Box>
        
        <Box textAlign="right">
          <Typography variant={ (subtitle || subtitle === 0) ? 'body1' : 'h6' } color="textSecondary">{title}</Typography>
          
          <Typography variant="h5" component="h2">
            { subtitle ?? '' }
          </Typography>
        </Box>
      </Box>
    </Link>
    
    {children && <Divider />}
    {children}
  </Card>
)

export const PaieCardWithIcon = ({ icon, title, subtitle, color, children }) => (
  <Card
    sx={{
      minHeight: 52,
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
      '& a': {
        textDecoration: 'none',
        color: 'inherit',
      },
    }}
  >
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        padding: '16px',
        '& .icon': {
          color: color || 'primary.light',
        },
        '&:before': {
          position: 'absolute',
          top: '50%',
          left: 0,
          display: 'block',
          content: `''`,
          height: '200%',
          aspectRatio: '1',
          transform: 'translate(-30%, -60%)',
          borderRadius: '50%',
          backgroundColor: 'complementary.main',
          opacity: 0.15,
        },
      }}
    >
      <Stack spacing={2} direction="row" alignContent="center" alignItems="center" justifyContent="space-between">
        <Box width="3em" className="icon">
          { createElement(icon, { fontSize: 'large' }) }
        </Box>
        
        <Box>
          <Typography variant="h5" component="h2">{title}</Typography>
          
          <Typography color="textSecondary">
            { subtitle ?? '' }
          </Typography>
        </Box>
      
        {children}
      </Stack>
    </Box>
  </Card>
)
