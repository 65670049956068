import {
  List,
  TextField,
  NumberField,
  DatagridConfigurable,
  useListContext
} from 'react-admin'

import EmptyMessage from './EmptyMessage'
import MonthToolbar from './MonthToolbar'
import HippocastListFilters from './HippocastListFilters'

const ListComponent = () => (
    <DatagridConfigurable bulkActionButtons={false}>
      <TextField source="event_name" label="Evènement" />
      <NumberField source="total_imported" label="Total Importé" />
      <NumberField source="new_leads" label="Nouveaux Leads" />
      <NumberField source="crm_leads" label="Leads déjà dans le CRM" />
      <NumberField source="sub_new_leads" label="Nouveaux Leads Inscrits" />
      {/* <NumberField source="sub_crm_leads" label="Leads déjà dans le CRM Inscrits" /> */}
    </DatagridConfigurable>
  )

const HippocastList = props => (
  <List
    {...props}
    resource="hippocast_summary"
    empty={<EmptyMessage />}
    aside={<HippocastListFilters />}
    actions={<MonthToolbar />}
  >
    <ListComponent />
  </List>
)

export default HippocastList