import React from 'react'
import { Breadcrumb } from '@react-admin/ra-navigation'
import { supabaseClient } from './supabase'
import { useGetIdentity } from 'react-admin'
import { getRole } from './common/roles'
import { getItemFromLocalStorage } from './common/LocalStorage'

const CustomBreadCrumb = ({ children, ...props }) => {
  const { identity } = useGetIdentity()
  const isAdmin = identity?.role?.grade === getRole('admin')
  const center = identity?.center?.name || 'Centre non défini'

  const currentYear = getItemFromLocalStorage('localCurrentYear')
  const currentYearId = currentYear?.id || process.env.REACT_APP_CURRENT_YEAR_ID
  const currentYearName = currentYear?.name || ''
  
  return (
    <Breadcrumb
      sx={{
        '& .RaBreadcrumb-list': {
          paddingTop: 2, paddingLeft: 1, paddingBottom: 1, borderBottom: '1px dotted #e0e0e0'
        },
      }}
    >
      {/* no Breadcrumb.ResourceItem for the 'leads' resource */}
      <Breadcrumb.DashboardItem>
        <Breadcrumb.ResourceItems resources={[
            'parents',
            'events',
            'types',
            'inbound_contacts',
            'tasks',
            'tags',
            'conversations',
            'profiles',
            'centers',
            'schools',
            'sites',
            'lead_years',
            'lead_sources',
            'qualifications',
            'brochures',
          ]}
        />
          {/* manually BC for leads */}
          <Breadcrumb.Item name="leads" label={
            currentYearId === process.env.REACT_APP_CURRENT_YEAR_ID
              ? isAdmin
                ? `Contacts 2024/2025`
                : `Contacts ${center} 2024/2025`
              : isAdmin
                ? `Contacts ${currentYearName} (consultation uniquement)`
                : `Contacts ${center} ${currentYearName} (consultation uniquement)`
            } 
          >
            <Breadcrumb.Item
              name="edit"
              label={({ record }) => record
                ? `Fiche contact "${record?.first_name} ${record?.last_name}"`
                : null}
              to={({ record }) => record ? `/leads/${record.id}` : null}
            />

            <Breadcrumb.Item
              name="create"
              label="Ajouter un contact"
              to="/leads/create"
            />
          </Breadcrumb.Item>

          {/* manually BC for calendar */}
          <Breadcrumb.Item name="calendar" label={
            currentYearId === process.env.REACT_APP_CURRENT_YEAR_ID
              ? isAdmin
                ? `Calendriers 2024/2025`
                : `Mon calendrier 2024/2025`
              : isAdmin
                ? `Calendriers ${currentYearName} (consultation uniquement)`
                : `Mon calendrier ${currentYearName} (consultation uniquement)`
            }
          >
            <Breadcrumb.Item
              name="edit"
              label={({ record }) => record
                ? record?.title
                : null }
              to={({ record }) => record ? `/calendar/${record.id}` : null}
            />

            <Breadcrumb.Item
              name="create"
              label="Créer un évènement"
              to="/calendar/create"
            />
          </Breadcrumb.Item>
      </Breadcrumb.DashboardItem>
    </Breadcrumb>
  )
}

export default CustomBreadCrumb