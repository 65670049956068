import { useState, useEffect } from 'react'
import { useGetIdentity } from 'react-admin'
import { List, ListItem, ListItemText, Avatar, ListItemAvatar } from '@mui/material'

import { supabaseClient } from '../../supabase'
import { CardWithIcon } from '../../Design/Cards'

import StarIcon from '@mui/icons-material/Star'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline'
import InterestsIcon from '@mui/icons-material/Interests'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'

const DashboardPubeurLeads = ({ isMobile }) => {
  const { identity } = useGetIdentity()

  const [stats, setStats] = useState({
    numOfFavouritedLeads: 0,
    numOfInterestedLeads: 0,
    numOfNewLeads: 0,
    numOfOldLeads: 0,
    numOfNewAssignedLeads: 0,
    numOfTotalLeads: 0
  })

  useEffect(() => {
    if (!identity?.id) return

    const fetchLeadsData = async () => {
      try {
        const today = new Date()
        const dayOfWeek = today.getDay()
        const lastMonday = new Date(today)
        lastMonday.setDate(today.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1))
        lastMonday.setHours(0, 0, 0, 0)

        const fetchLeadsView = supabaseClient
          .from('leads_view')
          .select('id, interested, favourited_by, previous')

        const fetchLeadsLogs = supabaseClient
          .from('newleads_logs')
          .select('lead_id')
          .eq('created_by', identity.id)
          .gte('created_at', lastMonday.toISOString())
        
        const fetchAssignationLogs = supabaseClient
          .from('assignation_logs')
          .select('lead_id')
          .eq('pubeur_id', identity.id)
          .gte('assigned_at', lastMonday.toISOString())

        const [leadsResponse, newLeadsResponse, assignationResponse] = await Promise.all([
          fetchLeadsView,
          fetchLeadsLogs,
          fetchAssignationLogs
        ])

        if ( leadsResponse.error || newLeadsResponse.error || assignationResponse.error ) {
          console.error('Error fetching leads data:', leadsResponse.error || newLeadsResponse.error || assignationResponse.error)
          return
        }

        const leadsData = leadsResponse.data || []
        const newLeadsData = newLeadsResponse.data || []
        const assignationData = assignationResponse.data || []

        const { favouritedCount, interestedCount, oldLeadsCount } = leadsData
          .reduce(
            (acc, lead) => {
              if (lead.favourited_by?.some(entry => entry.is_fav)) acc.favouritedCount++
              if (lead.interested) acc.interestedCount++
              if (lead.previous) acc.oldLeadsCount++
              return acc
            },
            { favouritedCount: 0, interestedCount: 0, oldLeadsCount: 0 }
          )

        // Count new assigned leads (not in leadsData)
        const assignedLeadIds = new Set(assignationData.map(a => a.lead_id))
        const newAssignedLeadsCount = [...assignedLeadIds].filter(id => !leadsData.some(lead => lead.id === id)).length

        setStats({
          numOfFavouritedLeads: favouritedCount,
          numOfInterestedLeads: interestedCount,
          numOfNewLeads: newLeadsData.length,
          numOfOldLeads: oldLeadsCount,
          numOfNewAssignedLeads: newAssignedLeadsCount,
          numOfTotalLeads: leadsData.length
        })
      } catch (error) {
        console.error('Unexpected error fetching leads data:', error)
      }
    }

    fetchLeadsData()
  }, [identity?.id]) // Only run when identity is available

  return (
    <div style={{ width: isMobile ? '100%' : '33.33333%' }}>
      <CardWithIcon icon={PeopleAltIcon} title="Mes contacts assignés" subtitle={stats.numOfTotalLeads} to="/leads" color="#ccc">
        <List sx={{ minHeight: '40vh' }} dense>
          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ backgroundColor: '#ede21a' }}>
                <StarIcon sx={{ color: '#fefefe' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`${stats.numOfFavouritedLeads} contacts favoris`} />
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ backgroundColor: '#52a1ce' }}>
                <InterestsIcon sx={{ color: '#fefefe' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`${stats.numOfInterestedLeads} contacts intéressés`} />
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ backgroundColor: '#91bcba' }}>
                <PersonAddAlt1Icon sx={{ color: '#fefefe' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`${stats.numOfNewLeads} contacts ajoutés cette semaine`} />
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ backgroundColor: '#91bcba' }}>
                <PersonAddAlt1Icon sx={{ color: '#fefefe' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`${stats.numOfNewAssignedLeads} nouveaux contacts assignés cette semaine`} />
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ backgroundColor: '#ccc' }}>
                <PeopleOutlineIcon sx={{ color: '#fefefe' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`${stats.numOfOldLeads} anciens contacts`} />
          </ListItem>
        </List>
      </CardWithIcon>
    </div>
  )
}

export default DashboardPubeurLeads
