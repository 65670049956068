import { defaultTheme } from 'react-admin'
import { deepmerge } from '@mui/utils'

const baseBackgroundColor = '#B4D4D3'
const baseColor = '#121212'
const complementaryLight = '#f4ebe5'

const theme = deepmerge(defaultTheme, {
  components: {
    RaCreateButton: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
        },
      },
    },
    RaDatagrid: {
      styleOverrides: {
        root: {
          '& .RaDatagrid-headerCell': {
            backgroundColor: complementaryLight,
          },
          '& .MuiTypography-root': {
            fontSize: '9.5pt',
          },
          '& .RaDatagrid-rowCell': {
            fontSize: '9.5pt', // for custom fields using regular html
          },
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: baseBackgroundColor,
          color: baseColor
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          // Default styles for all tabs can go here
          '& .Mui-selected': {
            backgroundColor: '#edf7f6',
          },
        },
      },
    },
    MuiGrid2: {
      styleOverrides: {
        container: {
          width: '100%'
        },
      },
    },
  },
  typography: {
    ...defaultTheme.typography,
    fontFamily: 'Poppins, Arial, sans-serif', // Set Poppins as the default font
    h6: { fontWeight: 600, color: '#444' },
    h5: { fontWeight: 600, color: '#444' },
    h4: { fontWeight: 700, color: '#333' },
    h3: { fontWeight: 700, color: '#333' },
    h2: { fontWeight: 800, color: '#222' },
    h1: { fontWeight: 800, color: '#222' },
  },
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: '#449295',
    },
    secondary: {
      main: '#b0c7bb',
      light: '#c6d1ce',
      dark: '#6f8a81',
    },
    ternary: {
      main: '#d5eae9',
      light: '#ECF1F1',
      dark: '#6CB6B2',
    },
    complementary: {
      main: '#c38b6e',
      light: complementaryLight,
      dark: '#b6714d',
    },
    info: {
      main: '#333'
    },
    tabs: {
      main: '#4e8988'
    },
    cards: {
      backgroundColor: {
        main: '#eee',
      },
      title: {
        main: '#325857',
      },
      contentBackgroundColor: {
        main: '#fff',
      },
    },
    gridList: {
      backgroundColor: complementaryLight,
      cards: {
        backgroundColor: '#fefefe',
        textColor: '#121212',
        textDate: '#444',
        icon: '#437c79',
        chipBackgroundColor: '#eee',
      }
    },
    filterPanel: {
      backgroundColor: '#e6f2f1',
      color: '#333',
    },
    favourited: {
      by: '#c84637'
    }
  },
})

export default theme