const pubeurPermissions = [
  { type: 'deny', action: 'delete', resource: 'leads' },
  { type: 'deny', action: 'delete', resource: 'parents' },
  { type: 'deny', action: 'delete', resource: 'lead_parents' },
  { type: 'deny', action: 'delete', resource: 'lead_rdv' },
  { type: 'deny', action: 'delete', resource: 'events' },
  { type: 'deny', action: 'delete', resource: 'schools' },
  { type: 'deny', action: ['delete', 'edit'], resource: 'centers' },
  { type: 'deny', action: '*', resource: 'types' },
  { type: 'deny', action: '*', resource: 'lead_years' },
  { type: 'deny', action: '*', resource: 'lead_sources' },
  { type: 'deny', action: '*', resource: 'qualifications' },
  { type: 'deny', action: '*', resource: 'tags' },
  { type: 'deny', action: '*', resource: 'tasks' },
  { type: 'deny', action: '*', resource: 'conversations' },
  { action: ['list', 'show', 'create', 'read', 'edit'], resource: 'leads' },
  { action: ['list', 'show', 'create', 'read', 'edit'], resource: 'parents' },
  { action: ['list', 'show', 'create', 'read', 'edit'], resource: 'lead_parents' },
  { action: ['list', 'show', 'create', 'read', 'edit'], resource: 'lead_rdv' },
  { action: ['list', 'show', 'create', 'read', 'edit', 'export'], resource: 'calendar' },
  { action: ['list', 'show', 'read', 'edit'], resource: 'events' },
  { action: ['list', 'show', 'read', 'edit'], resource: 'schools' },
  { action: ['list', 'show', 'read', 'edit'], resource: 'centers' },
]

const permissions = {
  // Admin
  100: [
    { action: ['list', 'show', 'create', 'edit', 'delete', 'export'], resource: '*' },
    { action: ['read', 'write'], resource: '*' },
  ],
  // Responsable
  50: [
    { type: 'deny', action: '*', resource: 'lead_years' },
    { type: 'deny', action: ['create'], resource: 'types' },
    { type: 'deny', action: '*', resource: 'lead_sources' },
    { type: 'deny', action: '*', resource: 'qualifications' },
    { type: 'deny', action: '*', resource: 'centers' },
    { action: ['list', 'show', 'create', 'edit', 'delete', 'export'], resource: '*' },
    { action: ['read', 'write'], resource: '*' },
  ],
  // Coordinateur
  30: [
    { type: 'deny', action: 'delete', resource: 'leads' },
    { type: 'deny', action: 'delete', resource: 'parents' },
    { type: 'deny', action: 'delete', resource: 'lead_parents' },
    { type: 'deny', action: 'delete', resource: 'lead_rdv' },
    { type: 'deny', action: '*', resource: 'lead_years' },
    { type: 'deny', action: '*', resource: 'lead_sources' },
    { type: 'deny', action: '*', resource: 'qualifications' },
    { type: 'deny', action: '*', resource: 'centers' },
    { type: 'deny', action: '*', resource: 'types' },
    { type: 'deny', action: '*', resource: 'lead_years' },
    { type: 'deny', action: '*', resource: 'lead_sources' },
    { type: 'deny', action: '*', resource: 'qualifications' },
    { action: ['list', 'show', 'create', 'edit'], resource: '*' },
    { action: ['read', 'write'], resource: '*' },
  ],
  // SDR
  20: [
    // { type: 'deny', action: 'delete', resource: 'leads' },
    { type: 'deny', action: 'delete', resource: 'leads' },
    { type: 'deny', action: 'delete', resource: 'parents' },
    { type: 'deny', action: 'delete', resource: 'lead_parents' },
    { type: 'deny', action: '*', resource: 'types' },
    { type: 'deny', action: '*', resource: 'lead_years' },
    { type: 'deny', action: '*', resource: 'lead_sources' },
    { type: 'deny', action: '*', resource: 'qualifications' },
    { type: 'deny', action: '*', resource: 'tags' },
    { type: 'deny', action: '*', resource: 'tasks' },
    { type: 'deny', action: '*', resource: 'conversations' },
    { type: 'deny', action: '*', resource: 'types' },
    { type: 'deny', action: '*', resource: 'lead_sources' },
    { type: 'deny', action: '*', resource: 'qualifications' },
    { type: 'deny', action: '*', resource: 'centers' },
    { action: ['list', 'show', 'create', 'edit'], resource: '*' },
    { action: ['read', 'write'], resource: '*' },
  ],
  // Prof
  10: [
    { type: 'deny', action: 'delete', resource: 'leads' },
    { type: 'deny', action: 'delete', resource: 'parents' },
    { type: 'deny', action: 'delete', resource: 'lead_parents' },
    { type: 'deny', action: '*', resource: 'types' },
    { type: 'deny', action: '*', resource: 'lead_years' },
    { type: 'deny', action: '*', resource: 'lead_sources' },
    { type: 'deny', action: '*', resource: 'qualifications' },
    { type: 'deny', action: '*', resource: 'types' },
    { type: 'deny', action: '*', resource: 'lead_years' },
    { type: 'deny', action: '*', resource: 'lead_sources' },
    { type: 'deny', action: '*', resource: 'qualifications' },
    { type: 'deny', action: '*', resource: 'tags' },
    { type: 'deny', action: '*', resource: 'tasks' },
    { type: 'deny', action: '*', resource: 'conversations' },
    { type: 'deny', action: '*', resource: 'types' },
    { type: 'deny', action: '*', resource: 'lead_sources' },
    { type: 'deny', action: '*', resource: 'qualifications' },
    { type: 'deny', action: '*', resource: 'centers' },
    { action: ['list', 'show', 'edit'], resource: '*' },
    { action: ['read', 'write'], resource: '*' },
  ],
  // Pubeur
  0: pubeurPermissions,
  // hippocast
  '-3': [
    { action: ['list'], resource: 'hippocast_summary' },
    { action: ['list'], resource: 'centers' },
  ]
}

export default permissions
