const customColor = (record, theme = 'light') => {
  const isDarkMode = theme === 'dark'

  let textColor = isDarkMode ? '#E0E0E0' : '#121212'
  let bgColor = 'transparent'

  if (record?.status_inscription_id !== undefined) {
    if (
      record?.interested &&
      record?.status_inscription_id !== 'ae180b6a-6711-421f-9344-5991dd678f50' &&
      record?.status_inscription_id !== null &&
      record?.status_inscription_id !== ''
    ) {
      textColor = isDarkMode ? '#fefefe' : '#fefefe'
      bgColor = isDarkMode ? '#449758' : '#4ba661'
    } else if (record?.interested) {
      textColor = isDarkMode ? '#50AF61' : '#008000'
      bgColor = 'transparent'
    }
    
    switch (record?.status_inscription_id) {
      case 'ae180b6a-6711-421f-9344-5991dd678f50': // candidat
        textColor = isDarkMode ? '#fff' : '#fff'
        bgColor = isDarkMode ? '#3077e0' : '#7ba8eb'
        break
      case null:
        textColor = isDarkMode ? '#B0B0B0' : '#111'
        bgColor = 'transparent'
        break
      default:
        textColor = '#fefefe'
        bgColor = isDarkMode ? '#2E8748' : '#8ecc9d'
        break
    }

    if (record?.status_inscription_id === null || record?.status_inscription_id === '') {
      switch (true) {
        case record?.not_interested:
          textColor = isDarkMode ? '#ff5e5e' : '#d11b1b'
          bgColor = 'transparent'
          break
        case record?.interested:
          textColor = isDarkMode ? '#519c9c' : '#008000'
          bgColor = 'transparent'
          break
      }
    }
  }

  if (record?.stop_rgpd) {
    textColor = '#fefefe'
    bgColor = isDarkMode ? '#a82825' : '#dd6764'
  }

  return { textColor, bgColor }
}

export default customColor
