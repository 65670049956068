import React, { useEffect, useState, useMemo } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { Admin, CustomRoutes, Resource } from 'react-admin'
import { ForgotPasswordPage } from 'ra-supabase'

import { fr } from 'date-fns/locale'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

import { useProfile } from './contexts/ProfileContext'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { ServiceWorkerProvider, useServiceWorker } from './contexts/ServiceWorkerContext'

import theme from './common/theme'
import LoginPage from './LoginPage'
import { MyLayout }  from './Layout'
import darkTheme from './common/darkTheme'
import { i18nProvider } from './i18nProvider'
import LoadingPage from './Design/LoadingPage'
import SetPasswordPage from './SetPasswordPage'
import ResetPasswordPage from './ResetPasswordPage'
import combinedDataProvider from './supabase/dataProvider'
import { ProfileProvider } from './contexts/ProfileContext'
import customAuthProvider from './supabase/customAuthProvider'

import Dashboard from './dashboards/'

const ReactAdminApp = () => {
  const { setIsUpdateAvailable, setUpdateAssets } = useServiceWorker()
  const { roleGrade } = useProfile()
  const authProvider = useMemo(() => customAuthProvider(roleGrade), [roleGrade])

  // State for dynamically loaded resources
  const [resources, setResources] = useState([])

  useEffect(() => {
    // Dynamically import resources
    Promise.all([
      import('./leads').then((mod) => ({ name: 'leads', module: mod.default })),
      import('./parents').then((mod) => ({ name: 'parents', module: mod.default })),
      import('./events').then((mod) => ({ name: 'events', module: mod.default })),
      import('./inboundContacts').then((mod) => ({ name: 'inbound_contacts', module: mod.default })),
      import('./tasks').then((mod) => ({ name: 'tasks', module: mod.default })),
      import('./tags').then((mod) => ({ name: 'tags', module: mod.default })),
      import('./conversations').then((mod) => ({ name: 'conversations', module: mod.default })),
      import('./profiles').then((mod) => ({ name: 'profiles', module: mod.default })),
      import('./centers').then((mod) => ({ name: 'centers', module: mod.default })),
      import('./eventTypes').then((mod) => ({ name: 'types', module: mod.default })),
      import('./schools').then((mod) => ({ name: 'schools', module: mod.default })),
      import('./sites').then((mod) => ({ name: 'sites', module: mod.default })),
      import('./leadYears').then((mod) => ({ name: 'lead_years', module: mod.default })),
      import('./leadSources').then((mod) => ({ name: 'lead_sources', module: mod.default })),
      import('./qualifications').then((mod) => ({ name: 'qualifications', module: mod.default })),
      import('./calendar').then((mod) => ({ name: 'calendar', module: mod.default })),
      import('./brochures').then((mod) => ({ name: 'brochures', module: mod.default })),
    ]).then(setResources)
  }, [])

  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: (registration) => {
        setIsUpdateAvailable(true)
  
        const refreshCacheAndReload = () => {
          console.log('Starting cache clearing and page reload process')
        
          const clearCache = () => {
            caches.keys().then(cacheNames => {
              cacheNames.forEach(cacheName => {
                console.log(`Deleting cache: ${cacheName}`)
                caches.delete(cacheName)
              })
            })
        
            console.log('Cache cleared, reloading the page')
            window.location.reload(true)
          }
        
          console.log('Unregistering service worker')
          serviceWorkerRegistration.unregister()
          clearCache()
        }
  
        const handleButtonClick = () => {
          if (registration && registration.waiting) {
            registration.waiting.postMessage({ type: 'SKIP_WAITING' })
  
            setIsUpdateAvailable(false)
  
            refreshCacheAndReload()
          }
        }
  
        setUpdateAssets(() => handleButtonClick)
      },
    })
  }, [])
  
  return (
    <Admin
      requireAuth
      loading={LoadingPage}
      dataProvider={combinedDataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      loginPage={LoginPage}
      layout={MyLayout}
      theme={theme}
      darkTheme={darkTheme}
      dashboard={Dashboard}
    >
      <CustomRoutes noLayout>
        <Route path="/set-password" element={<SetPasswordPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path={ForgotPasswordPage.path} element={<ForgotPasswordPage />} />
      </CustomRoutes>

      {/* Render Resources Dynamically */}
      {resources.map(({ name, module }) => (
        <Resource
          key={name}
          name={name}
          {...module}
          recordRepresentation={(record) => record?.name || `${record?.first_name} ${record?.last_name}`}
        />
      ))}
    </Admin>
  )
}

const App = () => (
  <ServiceWorkerProvider>
    <ProfileProvider>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
          <ReactAdminApp />
        </LocalizationProvider>
      </BrowserRouter>
    </ProfileProvider>
  </ServiceWorkerProvider>
)

export default App