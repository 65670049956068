import { mergeTranslations } from 'ra-core';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import frenchMessages from 'ra-language-french';
import { raSupabaseEnglishMessages } from 'ra-supabase-language-english';
import { raSupabaseFrenchMessages } from 'ra-supabase-language-french';

frenchMessages.csv = {
  buttonMain: {
    label: "Importer",
    tooltip: "Fichier '.csv' ou '.tsv' uniquement",
    emptyResource:
      "La propriété 'resource' était vide, avez-vous passé les {... props} à ImportButton ?",
  },
  parsing: {
    collidingIds: 'Champs "id" en collision trouvés',
    failedValidateRow: "CSV n'a pas satisfait aux exigences de validation",
    invalidCsv:
      'Le document n\'a pas pu être analysé en tant que fichier "csv"',
  },
  dialogCommon: {
    subtitle:
      'Importation de %{count} élément(s) de %{fileName} vers "%{resource}"',
    conflictCount:
      "La ressource <strong>%{resource}</strong> contient <strong>%{conflictingCount}</strong> enregistrements supplémentaires avec des identifiants en conflit",
    buttons: {
      cancel: "Annuler",
    },
  },
  dialogImport: {
    alertClose: "Contacts importés depuis %{fname}",
    title: 'Importation dans "%{resource}"',
    buttons: {
      replaceAllConflicts: "Remplacez les lignes",
      skipAllConflicts: "Ignorer ces lignes",
      letmeDecide: "Laisse-moi décider pour chaque ligne",
    },
  },
  dialogDecide: {
    title: 'Importation de l\'id %{id} vers "%{resource}"',
    buttons: {
      replaceRow: "Remplacer la ligne id=%{id}",
      addAsNewRow: "Ajouter comme nouvelle ligne (ne pas remplacer)",
      skipDontReplace: "Ignorer cette ligne (ne pas remplacer)",
    },
  },
  loading: "Chargement...",
}

frenchMessages.ra = {
  ...frenchMessages.ra ?? {},
  action: {
    ...frenchMessages.ra?.action ?? {},
    select_columns: "",
  }
}

englishMessages.ra = {
  ...englishMessages.ra ?? {},
  action: {
    ...englishMessages.ra?.action ?? {},
    select_columns: "",
  }
}

const allEnglishMessages = mergeTranslations(
    englishMessages,
    raSupabaseEnglishMessages
)

const allFrenchMessages = mergeTranslations(
    frenchMessages,
    raSupabaseFrenchMessages
)

export const i18nProvider = polyglotI18nProvider(
    locale => (locale === 'fr' ? allFrenchMessages : allEnglishMessages),
    'fr'
)
